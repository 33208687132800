<template>
  <div class="pp-container">
    <h1 class="">Privacy Policy</h1>
    <p>Last updated: July 2, 2023</p>
    <p class="">
      EcoGameCenter.net (the "Website") is committed to protecting your privacy. This
      Privacy Policy describes how your personal information is collected, used, and
      shared when you use our Services.
    </p>
    <ul style="margin-top: 40px">
      <li class="mb-5" v-for="(item, i) in paragraphes" :key="i">
        <h3>{{ item.title }}</h3>
        <p class="grey-text">{{ item.text }}</p>
      </li>
    </ul>
    <p>
      By using our Services, you agree to the terms of this Privacy Policy. Please review
      it periodically to keep informed of updates.
    </p>

    <h4 style="margin-top: 20px">Contact Us</h4>
    <p>
      If you have any questions or suggestions regarding our Privacy Policy, please
      contact us at support@ecogamecenter.net.
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      paragraphes: [
        {
          title: "1. Information We Collect",
          text:
            "When you register for an account, we collect your name, email address, and other necessary information.",
        },
        {
          title: "2. How We Use Your Information",
          text:
            "We use your personal information to provide and improve our Services, communicate with you about your account or transactions, and send you information about features and enhancements.",
        },
        {
          title: "3. Sharing Your Information",
          text:
            "We will not share your personal information with third parties without your consent, except in the necessary course of performing our Services or if required by law.",
        },
        {
          title: "4. Cookies",
          text:
            "We use cookies to understand how you use our site and to improve your experience. By continuing to use our site, you accept our use of cookies.",
        },
        {
          title: "5. Security",
          text:
            "We take reasonable measures, including administrative, technical, and physical safeguards, to protect your information from loss, theft, misuse, and unauthorized access.",
        },
        {
          title: "6. Changes to this Policy",
          text:
            "We may update this Privacy Policy from time to time. Changes will be posted on this page with an updated revision date.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.pp-container {
  padding: 100px 10%;
  color: white;
  @media only screen and (max-width: 450px) {
    padding: 100px 5%;
    h1 {
      font-size: 20px;
    }
    h3 {
      font-size: 13px;
    }
    p {
      font-size: 12px;
    }
  }
  li {
    list-style: none;
  }
}

p {
  margin-top: 10px;
}
.grey-text {
  color: rgb(179, 179, 179);
}
</style>
